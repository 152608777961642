import { h, Fragment } from 'preact';
import {
  DidomiButton,
  DidomiCard,
  DidomiLink,
  DidomiSwitchField,
} from '@didomi/ui-atoms-react';
import {
  useUserProfile,
  useAuthMethods,
  useSnackbar,
} from '@didomi/utility-react';
import { Formik, Form } from 'formik';
import { useUpdateUser } from '@hooks';
import * as Yup from 'yup';

const TERMS_OF_USE_VERSION = '1';
const PRIVACY_POLICY_VERSION = '1';

const RegistrationPartnerFormSchema = Yup.object().shape({
  termsOfUsePartner: Yup.boolean()
    .oneOf([true], 'Required')
    .required('Required'),
  privacyPolicyPartner: Yup.boolean()
    .oneOf([true], 'Required')
    .required('Required'),
});

export const AcceptTosAndPPPage = () => {
  const { logout } = useAuthMethods();
  const { displaySnackbar } = useSnackbar();
  const [userProfile] = useUserProfile();
  const { mutate: updateUserProfile } = useUpdateUser({
    onSuccess: () => {
      displaySnackbar('ToS and privacy policy accepted', {
        icon: 'success-small',
      });
      window.location.reload();
    },
    onError: () => {
      displaySnackbar(
        'There was an error accepting the ToS and privacy policy',
        {
          variant: 'error',
        },
      );
    },
  });

  const handleAcceptToSandPP = () => {
    displaySnackbar('Accepting ToS and Privacy policy...', { permanent: true });
    updateUserProfile({
      ...userProfile,
      terms_version: TERMS_OF_USE_VERSION,
      privacy_policy_version: PRIVACY_POLICY_VERSION,
    });
  };

  return (
    <div class="self-start h-full lg:min-h-screen! w-full p-xl bg-white text-center flex xl:(children:(max-w-[615px]))">
      <div class="flex flex-col w-full m-auto">
        <Formik
          initialValues={{
            termsOfUsePartner: false,
            privacyPolicyPartner: false,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={RegistrationPartnerFormSchema}
          onSubmit={handleAcceptToSandPP}
        >
          <Form class="flex flex-col w-full">
            <Fragment>
              <h1 class="font-serif text-[20px] leading-[25px] font-bold text-primary-blue-6 mb-xxxs">
                Please agree to our terms to continue
              </h1>

              <DidomiSwitchField
                class="w-full mt-s"
                id="terms-of-use-switch"
                name="termsOfUsePartner"
                valueSelected={true}
                valueNotSelected={false}
                data-testId="register-tos-input"
                data-cy="register-tos-input"
                hideErrorMessage
              >
                <div class="text-left text-body-small">
                  <span>I accept the </span>
                  <DidomiLink
                    class="children:(text-body-small! children:(text-primary-blue-6!))"
                    text="terms of use"
                    target="_blank"
                    href={`https://tos.console.didomi.io/v${TERMS_OF_USE_VERSION}`}
                  ></DidomiLink>
                  <span> *</span>
                </div>
              </DidomiSwitchField>
              <DidomiSwitchField
                class="w-full mt-s"
                id="privacy-policy-switch"
                name="privacyPolicyPartner"
                valueSelected={true}
                valueNotSelected={false}
                data-testId="register-privacy-policy-input"
                data-cy="register-privacy-policy-input"
                hideErrorMessage
              >
                <div class="text-left text-body-small">
                  <span>I accept </span>
                  <DidomiLink
                    class="children:(text-body-small! children:(text-primary-blue-6!))"
                    text="Didomi's privacy policy"
                    target="_blank"
                    href={`https://privacy.console.didomi.io/v${PRIVACY_POLICY_VERSION}`}
                  ></DidomiLink>
                  <span> *</span>
                </div>
              </DidomiSwitchField>
              <div class="mt-xxs font-sans text-primary-blue-6 italic text-[12px] text-left">
                * Required fields
              </div>
              <div class="mb-s mt-m">
                <DidomiButton
                  id="accept-tos-and-pp-continue"
                  data-testid="accept-tos-and-pp-continue"
                  type="submit"
                >
                  Continue
                </DidomiButton>
              </div>
              <div class="font-sans text-link-small text-primary-blue-6 mt-xxxs">
                <div class="text-tooltip mb-s text-justify">
                  Didomi collects your personal data for the purpose of
                  providing you with the required product or service on the
                  legal basis of the execution of the contract between you and
                  Didomi. Didomi also processes your personal data for
                  commercial prospecting purposes, to send you communications
                  about our products and services, our newsletter and other
                  marketing contents that you may be interested in. This
                  processing is based on our legitimate interest of commercial
                  development. You may opt out of receiving commercial
                  communications from us at any time by clicking on the
                  unsubscribe link at the bottom of any of our emails or by
                  visiting our{' '}
                  <DidomiLink
                    text="Preference Center"
                    class="children:(children:(text-tooltip! leading-tooltip!))"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://preferences.didomi.io/en/?__hstc=47810744.d5cf197267cfd5bc61215a8634161d0b.1652188454993.1652966312339.1652971901095.19&__hssc=47810744.2.1652971901095&__hsfp=4285294155&hsutk=d5cf197267cfd5bc61215a8634161d0b&contentType=standard-page&pageId=38081350101"
                  ></DidomiLink>
                  . Furthermore you have the right to access, delete, rectify
                  and object to the processing of your personal data by visiting
                  our{' '}
                  <DidomiLink
                    text="Privacy center"
                    class="children:(children:(text-tooltip! leading-tooltip!))"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://privacy.didomi.io/fr/"
                  ></DidomiLink>{' '}
                  or by contacting our DPO to the following email address:{' '}
                  <DidomiLink
                    text="dpo@didomi.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="children:(children:(text-tooltip! leading-tooltip!))"
                    href="mailto:dpo@didomi.io"
                  ></DidomiLink>
                  .
                </div>
                <DidomiLink
                  class="ml-xxxs children:(text-body-small!)"
                  text="Logout"
                  onClick={() => logout({})}
                ></DidomiLink>
              </div>
            </Fragment>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
