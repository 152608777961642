import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { DidomiCard, DidomiLink } from '@didomi/ui-atoms-react';
import {
  useEnvironment,
  useUserProfile,
  useAuthMethods,
  useSnackbar,
} from '@didomi/utility-react';
import { useResendEmail } from '@hooks';

export const VerifyEmailPage = () => {
  const { assetsBaseUrl } = useEnvironment();
  const { logout } = useAuthMethods();
  const [userProfile] = useUserProfile({ refetchOnFocus: true });
  const { displaySnackbar } = useSnackbar();
  const { mutate: resendEmail } = useResendEmail({
    onSuccess: () => {
      displaySnackbar('Email re-sent, please verify your inbox', {
        icon: 'success-small',
      });
    },
    onError: () => {
      displaySnackbar('There was an error resending the email', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    //@ts-ignore TODO: fix required in utility types
    if (userProfile?.email_verified) {
      window.location.reload();
    }
  }, [userProfile]);

  const handleResendEmail = () => {
    displaySnackbar('Preparing your email...', { permanent: true });
    resendEmail();
  };

  const logoutToRegister = () => {
    logout({
      redirectUri: `/auth/register`,
    });
  };

  return (
    <div class="flex items-center justify-center h-full w-full overflow-auto opacity-1 text-primary-blue-6">
      <DidomiCard class="h-full w-full md:(h-auto max-w-[592px]) children:(h-full w-full! justify-center md:(block h-auto justify-auto max-w-[592px]!))">
        <img
          className="w-s h-s mb-xs"
          src={assetsBaseUrl + '/Logo_Didomi_Standalone_Blue.svg'}
          data-testid="didomi-logo-image"
          alt=""
        />
        <h1 class="h1">Verify your email</h1>
        <div class="text-body-small mt-s" data-testid="verify-email-message">
          <div>
            Check your email at{' '}
            <span class="text-primary-pink-4">{userProfile?.email}</span>
          </div>
          <div>We have sent you a link to access your Didomi dashboard</div>
        </div>
        <hr class="w-4/5 mt-s" />
        <div class="text-body-small text-[12px] mt-s">
          If you didn&#39;t receive the email after a few minutes, check your
          spam folder or try to{' '}
          <DidomiLink
            class="inline"
            text="resend"
            onClick={handleResendEmail}
          ></DidomiLink>{' '}
          it
        </div>
        <div class="text-body-small text-[12px] mt-xxs">
          If you mistyped your address, you can{' '}
          <DidomiLink
            class="inline"
            text="restart and create a new account"
            onClick={logoutToRegister}
          ></DidomiLink>
        </div>
      </DidomiCard>
    </div>
  );
};
