import * as preact from 'preact';
import singleSpaPreact from 'single-spa-preact';
import { setUpDidomiUIAtoms } from '@didomi/ui-atoms-react';
import { ValidateAccountApp } from './index';

// Entry point for Single SPA
const lifecycles = singleSpaPreact({
  preact,
  rootComponent: ValidateAccountApp,
});

const { bootstrap, mount: mountOriginal, unmount } = lifecycles;

async function mount(opts, props) {
  await setUpDidomiUIAtoms('validate-account');
  mountOriginal(opts, props);
}

export { bootstrap, mount, unmount };
