import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAuthToken } from '@didomi/utility-react';
import { UserProfile } from '@didomi/utility/dist/typings/src/user-profile/models/user-profile.model';

/**
 * This hook is used to update the user profile
 */
export const useUpdateUser = (
  config?: UseMutationOptions<AxiosResponse, AxiosError, Partial<UserProfile>>,
): UseMutationResult<AxiosResponse, AxiosError, Partial<UserProfile>> => {
  const token = useAuthToken();

  return useMutation(
    async (userData: Partial<UserProfile>) =>
      await axios.patch(
        `${CONFIG.restServerUri}/users/${userData.id}`,
        {
          ...userData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    config,
  );
};
