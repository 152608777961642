import { h } from 'preact';
import { tw } from '@didomi/ui-foundation';
import { RegistrationBannerCard } from '../RegistrationBannerCard/RegistrationBannerCard';
import { useEnvironment } from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react';

export const RegistrationBanner = () => {
  const { assetsBaseUrl } = useEnvironment();
  const isLargeSize = useMediaQuery('(min-width: 1440px)');
  const isExtraLargeSize = useMediaQuery('(min-width: 1748px)');

  return (
    <div class="z-10 flex lg:(items-end!)">
      <div
        class={tw(' flex flex-col items-start text-center lg:(text-left!)', {
          'max-w-none!': isExtraLargeSize,
        })}
      >
        <h1 class="h1 text-[46px] leading-[48px] md:(text-[64px]! leading-[64px]!) font-bold text-primary-blue-6">
          Welcome to Didomi&apos;s
          <span class="text-primary-pink-5"> free compliance report</span>
        </h1>
        <div class="flex items-end ">
          <div class="flex flex-col 2xl:max-w-[477px]">
            <p class="text-body-normal md:text-body-big! leading-[26px] text-primary-blue-6 font-semibold mt-xs mx-s lg:mx-0">
              No credit card required
            </p>
            <div class="mt-l w-full flex flex-col gap-s items-center lg:items-start!">
              <RegistrationBannerCard
                number="1"
                text="Create your free Didomi's account and provide your website URL"
              />
              <RegistrationBannerCard
                number="2"
                text="Our tool will scan your website & tell you if your business complies with privacy regulations"
              />
              <RegistrationBannerCard
                number="3"
                text="Know exactly what actions you should be taking on your website to become compliant"
              />
            </div>
          </div>
          {isLargeSize && (
            <img
              width={240}
              height={300}
              alt=""
              src={assetsBaseUrl + '/global/Junon-compliance.svg'}
            />
          )}
        </div>
      </div>
    </div>
  );
};
