import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from 'react-query';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useAuthToken } from '@didomi/utility-react';
/**
 * This hook provides the action to resend the verification email
 */
export const useResendEmail = (
  config?: UseMutationOptions<AxiosResponse, AxiosError, void>,
): UseMutationResult<AxiosResponse, AxiosError, void> => {
  const token = useAuthToken();

  const mutation = useMutation(
    async () =>
      await axios.post(`${CONFIG.restServerUri}/users/verifications`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    config,
  );
  return mutation;
};
