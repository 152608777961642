import { h } from 'preact';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { useUserProfile } from '@didomi/utility-react';
import { AcceptTosAndPPPage, VerifyEmailPage } from '@pages';
import { CMSLayout } from '@layouts';

export const App = () => {
  const [userProfile, loadingUserProfile] = useUserProfile();

  if (loadingUserProfile) {
    return (
      <div class="bg-white opacity-80 absolute z-20 w-screen h-screen flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  if (!userProfile?.terms_version || !userProfile?.privacy_policy_version) {
    return (
      <CMSLayout>
        <AcceptTosAndPPPage />
      </CMSLayout>
    );
  }

  return <VerifyEmailPage />;
};
