import { h } from 'preact';
import { connect, theme } from '@didomi/ui-foundation';
import { App } from '@pages';

import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';
import { QueryClient, QueryClientProvider } from 'react-query';

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

connect('.validate-account', {
  theme: {
    extend: {
      ...theme.extend,
      backgroundImage: {
        ...theme.extend.backgroundImage,
        register:
          'linear-gradient(151.09deg, #FAFBFF 11.85%, #FAFBFF 52.03%, rgba(253, 232, 238, 0.7) 85.58%, #D4E5F9 102%)',
      },
    },
  },
});

const queryClient = new QueryClient();

export const ValidateAccountApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UtilityProvider utility={utility}>
        <App />
      </UtilityProvider>
    </QueryClientProvider>
  );
};
