import { h, FunctionalComponent } from 'preact';
import { tw } from '@didomi/ui-foundation';
import { RegistrationBanner } from '../../components/RegistrationBanner/RegistrationBanner';
import { useEnvironment } from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react';

/**
 * CMS Layout
 * Screen layout, with a side option to render news from the CMS
 */
const CMSLayout: FunctionalComponent = ({ children }) => {
  const { assetsBaseUrl } = useEnvironment();
  const isExtraLargeSize = useMediaQuery('(min-width: 1748px)');

  return (
    <section
      class="bg-register w-full min-h-full overflow-hidden relative flex flex-col items-center lg:(min-h-full items-start) p-0"
      data-testid="page-wrapper"
    >
      <div class="absolute left-0 bg-white w-[30vw]" />

      <div class="w-full flex-col lg:flex-row! z-10 flex justify-center items-center">
        <div class="w-full 2xl:w-7/12! z-10 flex-1 flex justify-start mb-m lg:(mb-0 justify-center!) 2xl:(px-[160px]!) items-center md:(px-l! py-m!)">
          <div class="flex justify-center">
            <div class="flex flex-col items-start">
              <img
                width={132}
                height={38}
                class="mb-l mt-l mx-auto lg:mx-0! z-10"
                alt="Didomi logo"
                src={assetsBaseUrl + '/Logo_Didomi_Full_Blue.svg'}
              />
              <img
                width={184}
                height={232}
                class="mb-l mt-l mx-auto lg:hidden lg:mx-0! z-10"
                alt=""
                src={assetsBaseUrl + '/global/Junon-compliance.svg'}
              />
              <RegistrationBanner />
            </div>
          </div>
        </div>
        <div
          class={tw(
            'w-full lg:max-w-[446px] xl:max-w-[500px] 2xl:(max-w-[615px] bg-white flex items-start justify-start)',
            { 'max-w-none! w-5/12!': isExtraLargeSize },
          )}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

export { CMSLayout };
