import { h } from 'preact';
import { DidomiIcon } from '@didomi/ui-atoms-react';

type RegistrationBannerCardProps = {
  number: string;
  text: string;
};

export const RegistrationBannerCard = ({
  number,
  text,
}: RegistrationBannerCardProps) => {
  return (
    <div class="flex justify-start items-center font-sans text-body-normal text-primary-blue-6 text-left">
      <div class="h2 h-12 w-12 min-w-[3rem] rounded-full border-1 border-solid border-primary-blue-5 flex items-center justify-center mr-s">
        {number}
      </div>
      <div class="max-w-[280px]">{text}</div>
    </div>
  );
};
